export default {
  'RUB': {
    symbolUnicode: '20BD',
    shortLocalName: 'руб',
    locales: {
      'ru-RU': 'Российский рубль',
      'en-US': 'Russian Ruble'
    }
  },
  'USD': {
    symbolUnicode: '0024',
    locales: {
      'ru-RU': 'Доллар США',
      'en-US': 'US Dollar'
    }
  },
  'EUR': {
    symbolUnicode: '20AC',
    locales: {
      'ru-RU': 'Евро',
      'en-US': 'Euro'
    }
  },
  'AED': {
    shortLocalName: 'Dh',
    locales: {
      'ru-RU': 'Дирхам (ОАЭ)',
      'en-US': 'UAE Dirham'
    }
  },
  'AFN': {
    symbolUnicode: '060B',
    locales: {
      'ru-RU': 'Афгани',
      'en-US': 'Afghani'
    }
  },
  'ALL': {
    locales: {
      'ru-RU': 'Лек',
      'en-US': 'Lek'
    }
  },
  'AMD': {
    locales: {
      'ru-RU': 'Армянский драм',
      'en-US': 'Armenian Dram'
    }
  },
  'ANG': {
    symbolUnicode: '0192',
    locales: {
      'ru-RU': 'Нидерландский антильский гульден',
      'en-US': 'Netherlands Antillean Guilder'
    }
  },
  'AOA': {
    locales: {
      'ru-RU': 'Кванза',
      'en-US': 'Kwanza'
    }
  },
  'ARS': {
    symbolUnicode: '0024',
    locales: {
      'ru-RU': 'Аргентинское песо',
      'en-US': 'Argentine Peso'
    }
  },
  'AUD': {
    symbolUnicode: '0024',
    shortLocalName: 'AU$',
    locales: {
      'ru-RU': 'Австралийский доллар',
      'en-US': 'Australian Dollar'
    }
  },
  'AWG': {
    symbolUnicode: '0192',
    locales: {
      'ru-RU': 'Арубанский флорин',
      'en-US': 'Aruban Florin'
    }
  },
  'AZN': {
    symbolUnicode: '20BC',
    shortLocalName: 'man.',
    locales: {
      'ru-RU': 'Азербайджанский манат',
      'en-US': 'Azerbaijanian Manat'
    }
  },
  'BAM': {
    shortLocalName: 'KM',
    locales: {
      'ru-RU': 'Конвертируемая марка',
      'en-US': 'Convertible Mark'
    }
  },
  'BBD': {
    symbolUnicode: '0024',
    locales: {
      'ru-RU': 'Барбадосский доллар',
      'en-US': 'Barbados Dollar'
    }
  },
  'BDT': {
    symbolUnicode: '09F3',
    shortLocalName: 'Tk',
    locales: {
      'ru-RU': 'Така',
      'en-US': 'Taka'
    }
  },
  'BGN': {
    shortLocalName: 'лв.',
    locales: {
      'ru-RU': 'Болгарский лев',
      'en-US': 'Bulgarian Lev'
    }
  },
  'BHD': {
    locales: {
      'ru-RU': 'Бахрейнский динар',
      'en-US': 'Bahraini Dinar'
    }
  },
  'BIF': {
    locales: {
      'ru-RU': 'Бурундийский франк',
      'en-US': 'Burundi Franc'
    }
  },
  'BMD': {
    symbolUnicode: '0024',
    shortLocalName: 'DB$',
    locales: {
      'ru-RU': 'Бермудский доллар',
      'en-US': 'Bermudian Dollar'
    }
  },
  'BND': {
    symbolUnicode: '0024',
    shortLocalName: 'B$',
    locales: {
      'ru-RU': 'Брунейский доллар',
      'en-US': 'Brunei Dollar'
    }
  },
  'BOB': {
    symbolUnicode: '0024',
    shortLocalName: 'Bs',
    locales: {
      'ru-RU': 'Боливиано',
      'en-US': 'Boliviano'
    }
  },
  'BRL': {
    symbolUnicode: '0024',
    shortLocalName: 'R$',
    locales: {
      'ru-RU': 'Бразильский реал',
      'en-US': 'Brazilian Real'
    }
  },
  'BSD': {
    symbolUnicode: '0024',
    shortLocalName: 'B$',
    locales: {
      'ru-RU': 'Багамский доллар',
      'en-US': 'Bahamian Dollar'
    }
  },
  'BTN': {
    shortLocalName: 'Nu',
    locales: {
      'ru-RU': 'Нгултрум',
      'en-US': 'Ngultrum'
    }
  },
  'BWP': {
    locales: {
      'ru-RU': 'Пула',
      'en-US': 'Pula'
    }
  },
  'BYN': {
    shortLocalName: 'р.',
    locales: {
      'ru-RU': 'Белорусский рубль',
      'en-US': 'Belarusian Ruble'
    }
  },
  'BZD': {
    symbolUnicode: '0024',
    shortLocalName: 'BZ$',
    locales: {
      'ru-RU': 'Белизский доллар',
      'en-US': 'Belize Dollar'
    }
  },
  'CAD': {
    symbolUnicode: '0024',
    shortLocalName: 'C$',
    locales: {
      'ru-RU': 'Канадский доллар',
      'en-US': 'Canadian Dollar'
    }
  },
  'CDF': {
    locales: {
      'ru-RU': 'Конголезский франк',
      'en-US': 'Congolese Franc'
    }
  },
  'CHF': {
    shortLocalName: 'Fr.',
    locales: {
      'ru-RU': 'Швейцарский франк',
      'en-US': 'Swiss Franc'
    }
  },
  'CKD': {
    locales: {
      'ru-RU': 'Доллар Островов Кука',
      'en-US': 'Cook Islands dollar'
    }
  },
  'CLP': {
    symbolUnicode: '0024',
    locales: {
      'ru-RU': 'Чилийское песо',
      'en-US': 'Chilean Peso'
    }
  },
  'CNY': {
    symbolUnicode: '00A5',
    locales: {
      'ru-RU': 'Юань',
      'en-US': 'Yuan Renminbi'
    }
  },
  'COP': {
    symbolUnicode: '0024',
    locales: {
      'ru-RU': 'Колумбийское песо',
      'en-US': 'Colombian Peso'
    }
  },
  'CRC': {
    symbolUnicode: '20A1',
    locales: {
      'ru-RU': 'Коста-риканский колон',
      'en-US': 'Costa Rican Colon'
    }
  },
  'CUP': {
    symbolUnicode: '0024',
    locales: {
      'ru-RU': 'Кубинское песо',
      'en-US': 'Cuban Peso'
    }
  },
  'CVE': {
    symbolUnicode: '0024',
    locales: {
      'ru-RU': 'Эскудо Кабо-Верде',
      'en-US': 'Cape Verde Escudo'
    }
  },
  'CZK': {
    shortLocalName: 'Kč',
    locales: {
      'ru-RU': 'Чешская крона',
      'en-US': 'Czech Koruna'
    }
  },
  'DJF': {
    locales: {
      'ru-RU': 'Франк Джибути',
      'en-US': 'Djibouti Franc'
    }
  },
  'DKK': {
    shortLocalName: 'kr.',
    locales: {
      'ru-RU': 'Датская крона',
      'en-US': 'Danish Krone'
    }
  },
  'DOP': {
    symbolUnicode: '0024',
    shortLocalName: 'RD$',
    locales: {
      'ru-RU': 'Доминиканское песо',
      'en-US': 'Dominican Peso'
    }
  },
  'DZD': {
    locales: {
      'ru-RU': 'Алжирский динар',
      'en-US': 'Algerian Dinar'
    }
  },
  'EGP': {
    symbolUnicode: '00A3',
    locales: {
      'ru-RU': 'Египетский фунт',
      'en-US': 'Egyptian Pound'
    }
  },
  'ERN': {
    shortLocalName: 'Nfk',
    locales: {
      'ru-RU': 'Накфа',
      'en-US': 'Nakfa'
    }
  },
  'ETB': {
    shortLocalName: 'Br',
    locales: {
      'ru-RU': 'Эфиопский быр',
      'en-US': 'Ethiopian Birr'
    }
  },
  'FJD': {
    symbolUnicode: '0024',
    shortLocalName: 'FJ$',
    locales: {
      'ru-RU': 'Доллар Фиджи',
      'en-US': 'Fiji Dollar'
    }
  },
  'FKP': {
    symbolUnicode: '00A3',
    locales: {
      'ru-RU': 'Фунт Фолклендских островов',
      'en-US': 'Falkland Islands Pound'
    }
  },
  'FOK': {
    locales: {
      'ru-RU': 'Фарерские кроны',
      'en-US': 'Faeroe Islands Kronur'
    }
  },
  'GBP': {
    symbolUnicode: '00A3',
    locales: {
      'ru-RU': 'Фунт стерлингов',
      'en-US': 'Pound Sterling'
    }
  },
  'GEL': {
    symbolUnicode: '20BE',
    locales: {
      'ru-RU': 'Лари',
      'en-US': 'Lari'
    }
  },
  'GGP': {
    locales: {
      'ru-RU': 'Гернсийский фунт',
      'en-US': 'Guernsey pound'
    }
  },
  'GHS': {
    symbolUnicode: '20B5',
    locales: {
      'ru-RU': 'Ганский седи',
      'en-US': 'Ghana Cedi'
    }
  },
  'GIP': {
    symbolUnicode: '00A3',
    locales: {
      'ru-RU': 'Гибралтарский фунт',
      'en-US': 'Gibraltar Pound'
    }
  },
  'GMD': {
    shortLocalName: 'D',
    locales: {
      'ru-RU': 'Даласи',
      'en-US': 'Dalasi'
    }
  },
  'GNF': {
    locales: {
      'ru-RU': 'Гвинейский франк',
      'en-US': 'Guinea Franc'
    }
  },
  'GTQ': {
    shortLocalName: 'Q',
    locales: {
      'ru-RU': 'Кетсаль',
      'en-US': 'Quetzal'
    }
  },
  'GYD': {
    symbolUnicode: '0024',
    shortLocalName: 'GY$',
    locales: {
      'ru-RU': 'Гайанский доллар',
      'en-US': 'Guyana Dollar'
    }
  },
  'HKD': {
    symbolUnicode: '0024',
    shortLocalName: 'HK$',
    locales: {
      'ru-RU': 'Гонконгский доллар',
      'en-US': 'Hong Kong Dollar'
    }
  },
  'HNL': {
    shortLocalName: 'L',
    locales: {
      'ru-RU': 'Лемпира',
      'en-US': 'Lempira'
    }
  },
  'HRK': {
    shortLocalName: 'kn',
    locales: {
      'ru-RU': 'Хорватская куна',
      'en-US': 'Kuna'
    }
  },
  'HTG': {
    locales: {
      'ru-RU': 'Гурд',
      'en-US': 'Gourde'
    }
  },
  'HUF': {
    symbolUnicode: '0192',
    shortLocalName: 'Ft',
    locales: {
      'ru-RU': 'Форинт',
      'en-US': 'Forint'
    }
  },
  'IDR': {
    symbolUnicode: '20A8',
    shortLocalName: 'Rp',
    locales: {
      'ru-RU': 'Рупия',
      'en-US': 'Rupiah'
    }
  },
  'ILS': {
    symbolUnicode: '20AA',
    locales: {
      'ru-RU': 'Новый израильский шекель',
      'en-US': 'New Israeli Sheqel'
    }
  },
  'IMP': {
    locales: {
      'ru-RU': 'Фунты Острова Мэн',
      'en-US': 'Manx pound'
    }
  },
  'INR': {
    symbolUnicode: '20B9',
    shortLocalName: 'Rs.',
    locales: {
      'ru-RU': 'Индийская рупия',
      'en-US': 'Indian Rupee'
    }
  },
  'IQD': {
    locales: {
      'ru-RU': 'Иракский динар',
      'en-US': 'Iraqi Dinar'
    }
  },
  'IRR': {
    symbolUnicode: 'FDFC',
    locales: {
      'ru-RU': 'Иранский риал',
      'en-US': 'Iranian Rial'
    }
  },
  'ISK': {
    shortLocalName: 'kr.',
    locales: {
      'ru-RU': 'Исландская крона',
      'en-US': 'Iceland Krona'
    }
  },
  'JMD': {
    symbolUnicode: '0024',
    shortLocalName: 'J$',
    locales: {
      'ru-RU': 'Ямайский доллар',
      'en-US': 'Jamaican Dollar'
    }
  },
  'JOD': {
    locales: {
      'ru-RU': 'Иорданский динар',
      'en-US': 'Jordanian Dinar'
    }
  },
  'JPY': {
    symbolUnicode: '00A5',
    locales: {
      'ru-RU': 'Иена',
      'en-US': 'Yen'
    }
  },
  'KES': {
    locales: {
      'ru-RU': 'Кенийский шиллинг',
      'en-US': 'Kenyan Shilling'
    }
  },
  'KGS': {
    shortLocalName: 'сом',
    locales: {
      'ru-RU': 'Сом',
      'en-US': 'Som'
    }
  },
  'KHR': {
    symbolUnicode: '17DB',
    locales: {
      'ru-RU': 'Риель',
      'en-US': 'Riel'
    }
  },
  'KID': {
    locales: {
      'ru-RU': 'Доллар Кирибати',
      'en-US': 'Kiribati Dollar'
    }
  },
  'KMF': {
    locales: {
      'ru-RU': 'Франк Комор',
      'en-US': 'Comoro Franc'
    }
  },
  'KPW': {
    symbolUnicode: '20A9',
    locales: {
      'ru-RU': 'Северокорейская вона',
      'en-US': 'North Korean Won'
    }
  },
  'KRW': {
    symbolUnicode: '20A9',
    locales: {
      'ru-RU': 'Вона',
      'en-US': 'Won'
    }
  },
  'KWD': {
    locales: {
      'ru-RU': 'Кувейтский динар',
      'en-US': 'Kuwaiti Dinar'
    }
  },
  'KYD': {
    symbolUnicode: '0024',
    locales: {
      'ru-RU': 'Доллар Островов Кайман',
      'en-US': 'Cayman Islands Dollar'
    }
  },
  'KZT': {
    symbolUnicode: '20B8',
    shortLocalName: 'тңг',
    locales: {
      'ru-RU': 'Тенге',
      'en-US': 'Tenge'
    }
  },
  'LAK': {
    symbolUnicode: '20AD',
    locales: {
      'ru-RU': 'Кип',
      'en-US': 'Kip'
    }
  },
  'LBP': {
    symbolUnicode: '00A3',
    locales: {
      'ru-RU': 'Ливанский фунт',
      'en-US': 'Lebanese Pound'
    }
  },
  'LKR': {
    symbolUnicode: '20A8',
    shortLocalName: 'Rs',
    locales: {
      'ru-RU': 'Шри-Ланкийская рупия',
      'en-US': 'Sri Lanka Rupee'
    }
  },
  'LRD': {
    symbolUnicode: '0024',
    shortLocalName: 'L$',
    locales: {
      'ru-RU': 'Либерийский доллар',
      'en-US': 'Liberian Dollar'
    }
  },
  'LSL': {
    shortLocalName: 'L',
    locales: {
      'ru-RU': 'Лоти',
      'en-US': 'Loti'
    }
  },
  'LYD': {
    locales: {
      'ru-RU': 'Ливийский динар',
      'en-US': 'Libyan Dinar'
    }
  },
  'MAD': {
    locales: {
      'ru-RU': 'Марокканский дирхам',
      'en-US': 'Moroccan Dirham'
    }
  },
  'MDL': {
    locales: {
      'ru-RU': 'Молдавский лей',
      'en-US': 'Moldovan Leu'
    }
  },
  'MGA': {
    locales: {
      'ru-RU': 'Малагасийский ариари',
      'en-US': 'Malagasy Ariary'
    }
  },
  'MKD': {
    shortLocalName: 'ден.',
    locales: {
      'ru-RU': 'Денар',
      'en-US': 'Denar'
    }
  },
  'MMK': {
    shortLocalName: 'K',
    locales: {
      'ru-RU': 'Кьят',
      'en-US': 'Kyat'
    }
  },
  'MNT': {
    symbolUnicode: '20AE',
    locales: {
      'ru-RU': 'Тугрик',
      'en-US': 'Tugrik'
    }
  },
  'MOP': {
    symbolUnicode: '0024',
    locales: {
      'ru-RU': 'Патака',
      'en-US': 'Pataca'
    }
  },
  'MRU': {
    locales: {
      'ru-RU': 'Угия',
      'en-US': 'Ouguiya'
    }
  },
  'MUR': {
    symbolUnicode: '20A8',
    locales: {
      'ru-RU': 'Маврикийская рупия',
      'en-US': 'Mauritius Rupee'
    }
  },
  'MVR': {
    shortLocalName: 'Rf',
    locales: {
      'ru-RU': 'Руфия',
      'en-US': 'Rufiyaa'
    }
  },
  'MWK': {
    locales: {
      'ru-RU': 'Квача',
      'en-US': 'Kwacha'
    }
  },
  'MXN': {
    symbolUnicode: '0024',
    locales: {
      'ru-RU': 'Мексиканское песо',
      'en-US': 'Mexican Peso'
    }
  },
  'MYR': {
    shortLocalName: 'RM',
    locales: {
      'ru-RU': 'Малайзийский ринггит',
      'en-US': 'Malaysian Ringgit'
    }
  },
  'MZN': {
    locales: {
      'ru-RU': 'Мозамбикский метикал',
      'en-US': 'Mozambique Metical'
    }
  },
  'NAD': {
    symbolUnicode: '0024',
    shortLocalName: 'N$',
    locales: {
      'ru-RU': 'Доллар Намибии',
      'en-US': 'Namibia Dollar'
    }
  },
  'NGN': {
    symbolUnicode: '20A6',
    locales: {
      'ru-RU': 'Найра',
      'en-US': 'Naira'
    }
  },
  'NIO': {
    symbolUnicode: '0024',
    shortLocalName: 'C$',
    locales: {
      'ru-RU': 'Золотая кордоба',
      'en-US': 'Cordoba Oro'
    }
  },
  'NOK': {
    shortLocalName: 'kr',
    locales: {
      'ru-RU': 'Норвежская крона',
      'en-US': 'Norwegian Krone'
    }
  },
  'NPR': {
    symbolUnicode: '20A8',
    locales: {
      'ru-RU': 'Непальская рупия',
      'en-US': 'Nepalese Rupee'
    }
  },
  'NZD': {
    symbolUnicode: '0024',
    locales: {
      'ru-RU': 'Новозеландский доллар',
      'en-US': 'New Zealand Dollar'
    }
  },
  'OMR': {
    symbolUnicode: 'FDFC',
    locales: {
      'ru-RU': 'Оманский риал',
      'en-US': 'Rial Omani'
    }
  },
  'PAB': {
    shortLocalName: 'B',
    locales: {
      'ru-RU': 'Бальбоа',
      'en-US': 'Balboa'
    }
  },
  'PEN': {
    shortLocalName: 'S/.',
    locales: {
      'ru-RU': 'Новый соль',
      'en-US': 'Nuevo Sol'
    }
  },
  'PGK': {
    shortLocalName: 'K',
    locales: {
      'ru-RU': 'Кина',
      'en-US': 'Kina'
    }
  },
  'PHP': {
    symbolUnicode: '20B1',
    shortLocalName: 'PhP',
    locales: {
      'ru-RU': 'Филиппинское песо',
      'en-US': 'Philippine Peso'
    }
  },
  'PKR': {
    symbolUnicode: '20A8',
    locales: {
      'ru-RU': 'Пакистанская рупия',
      'en-US': 'Pakistan Rupee'
    }
  },
  'PLN': {
    shortLocalName: 'zł',
    locales: {
      'ru-RU': 'Злотый',
      'en-US': 'Zloty'
    }
  },
  'PYG': {
    symbolUnicode: '20B2',
    shortLocalName: 'Gs',
    locales: {
      'ru-RU': 'Гуарани',
      'en-US': 'Guarani'
    }
  },
  'QAR': {
    symbolUnicode: 'FDFC',
    locales: {
      'ru-RU': 'Катарский риал',
      'en-US': 'Qatari Rial'
    }
  },
  'RON': {
    shortLocalName: 'lei',
    locales: {
      'ru-RU': 'Румынский лей',
      'en-US': 'Romanian Leu'
    }
  },
  'RSD': {
    shortLocalName: 'Дин.',
    locales: {
      'ru-RU': 'Сербский динар',
      'en-US': 'Serbian Dinar'
    }
  },
  'RWF': {
    locales: {
      'ru-RU': 'Франк Руанды',
      'en-US': 'Rwanda Franc'
    }
  },
  'SAR': {
    symbolUnicode: 'FDFC',
    locales: {
      'ru-RU': 'Саудовский риял',
      'en-US': 'Saudi Riyal'
    }
  },
  'SBD': {
    symbolUnicode: '0024',
    shortLocalName: 'SI$',
    locales: {
      'ru-RU': 'Доллар Соломоновых Островов',
      'en-US': 'Solomon Islands Dollar'
    }
  },
  'SCR': {
    locales: {
      'ru-RU': 'Сейшельская рупия',
      'en-US': 'Seychelles Rupee'
    }
  },
  'SDG': {
    symbolUnicode: '00A3',
    locales: {
      'ru-RU': 'Суданский фунт',
      'en-US': 'Sudanese Pound'
    }
  },
  'SEK': {
    shortLocalName: 'kr',
    locales: {
      'ru-RU': 'Шведская крона',
      'en-US': 'Swedish Krona'
    }
  },
  'SGD': {
    symbolUnicode: '0024',
    shortLocalName: 'S$',
    locales: {
      'ru-RU': 'Сингапурский доллар',
      'en-US': 'Singapore Dollar'
    }
  },
  'SHP': {
    symbolUnicode: '00A3',
    locales: {
      'ru-RU': 'Фунт Святой Елены',
      'en-US': 'Saint Helena Pound'
    }
  },
  'SLL': {
    shortLocalName: 'Le',
    locales: {
      'ru-RU': 'Леоне',
      'en-US': 'Leone'
    }
  },
  'SOS': {
    locales: {
      'ru-RU': 'Сомалийский шиллинг',
      'en-US': 'Somali Shilling'
    }
  },
  'SRD': {
    symbolUnicode: '0024',
    locales: {
      'ru-RU': 'Суринамский доллар',
      'en-US': 'Surinam Dollar'
    }
  },
  'SSP': {
    locales: {
      'ru-RU': 'Южносуданский фунт',
      'en-US': 'South Sudanese Pound'
    }
  },
  'STN': {
    locales: {
      'ru-RU': 'Добра',
      'en-US': 'Dobra'
    }
  },
  'SYP': {
    symbolUnicode: '00A3',
    locales: {
      'ru-RU': 'Сирийский фунт',
      'en-US': 'Syrian Pound'
    }
  },
  'SZL': {
    locales: {
      'ru-RU': 'Лилангени',
      'en-US': 'Lilangeni'
    }
  },
  'THB': {
    symbolUnicode: '0E3F',
    locales: {
      'ru-RU': 'Бат',
      'en-US': 'Baht'
    }
  },
  'TJS': {
    shortLocalName: 'смн.',
    locales: {
      'ru-RU': 'Сомони',
      'en-US': 'Somoni'
    }
  },
  'TMT': {
    shortLocalName: 'm.',
    locales: {
      'ru-RU': 'Туркменский манат',
      'en-US': 'Turkmenistan Manat'
    }
  },
  'TND': {
    locales: {
      'ru-RU': 'Тунисский динар',
      'en-US': 'Tunisian Dinar'
    }
  },
  'TOP': {
    symbolUnicode: '0024',
    shortLocalName: 'T$',
    locales: {
      'ru-RU': 'Паанга',
      'en-US': 'Pa’anga'
    }
  },
  'TRY': {
    symbolUnicode: '20BA',
    shortLocalName: 'YTL',
    locales: {
      'ru-RU': 'Турецкая лира',
      'en-US': 'Turkish Lira'
    }
  },
  'TTD': {
    symbolUnicode: '0024',
    shortLocalName: 'TT$',
    locales: {
      'ru-RU': 'Доллар Тринидада и Тобаго',
      'en-US': 'Trinidad and Tobago Dollar'
    }
  },
  'TWD': {
    symbolUnicode: '0024',
    shortLocalName: 'NT$',
    locales: {
      'ru-RU': 'Новый тайваньский доллар',
      'en-US': 'New Taiwan Dollar'
    }
  },
  'TZS': {
    locales: {
      'ru-RU': 'Танзанийский шиллинг',
      'en-US': 'Tanzanian Shilling'
    }
  },
  'UAH': {
    symbolUnicode: '20B4',
    shortLocalName: 'грн.',
    locales: {
      'ru-RU': 'Гривна',
      'en-US': 'Hryvnia'
    }
  },
  'UGX': {
    locales: {
      'ru-RU': 'Угандийский шиллинг',
      'en-US': 'Uganda Shilling'
    }
  },
  'UYU': {
    symbolUnicode: '0024',
    locales: {
      'ru-RU': 'Уругвайское песо',
      'en-US': 'Peso Uruguayo'
    }
  },
  'UZS': {
    locales: {
      'ru-RU': 'Узбекский сум',
      'en-US': 'Uzbekistan Sum'
    }
  },
  'VEF': {
    shortLocalName: 'Bs',
    locales: {
      'ru-RU': 'Венесуэльский боливар',
      'en-US': 'Venezuelan bolívar'
    }
  },
  'VND': {
    symbolUnicode: '20AB',
    locales: {
      'ru-RU': 'Донг',
      'en-US': 'Dong'
    }
  },
  'VUV': {
    shortLocalName: 'Vt',
    locales: {
      'ru-RU': 'Вату',
      'en-US': 'Vatu'
    }
  },
  'WST': {
    symbolUnicode: '0024',
    shortLocalName: 'WS$',
    locales: {
      'ru-RU': 'Тала',
      'en-US': 'Tala'
    }
  },
  'XAF': {
    locales: {
      'ru-RU': 'Франк КФА BEAC',
      'en-US': 'CFA Franc BEAC'
    }
  },
  'XCD': {
    symbolUnicode: '0024',
    shortLocalName: 'EC$',
    locales: {
      'ru-RU': 'Восточно-карибский доллар',
      'en-US': 'East Caribbean Dollar'
    }
  },
  'XOF': {
    locales: {
      'ru-RU': 'Франк КФА BCEAO',
      'en-US': 'CFA Franc BCEAO'
    }
  },
  'XPF': {
    locales: {
      'ru-RU': 'Тихоокеанский франк (Франк КФП)',
      'en-US': 'CFP Franc'
    }
  },
  'YER': {
    symbolUnicode: 'FDFC',
    locales: {
      'ru-RU': 'Йеменский риал',
      'en-US': 'Yemeni Rial'
    }
  },
  'ZAR': {
    shortLocalName: 'R',
    locales: {
      'ru-RU': 'Рэнд',
      'en-US': 'Rand'
    }
  },
  'ZMW': {
    locales: {
      'ru-RU': 'Замбийская квача',
      'en-US': 'Zambian Kwacha'
    }
  }
}
